import React from 'react'

async function accessLocalNetwork() {
  const response = await fetch('http://192.168.1.95:3000', {
    method: 'GET'
  })

  const body = await response.json()

  return body
}

function App() {
  const [localNetworkData, setLocalNetworkData] = React.useState(null)

  React.useEffect(() => {
    accessLocalNetwork()
      .then(data => {
        setLocalNetworkData(data)
      })
  })

  return (
    <div>
      {localNetworkData && JSON.stringify(localNetworkData, null, 2)}
    </div>
  )
}

export default App;
